import { render, staticRenderFns } from "./LoadingSpinner.vue?vue&type=template&id=3423d853&scoped=true&"
import script from "./LoadingSpinner.vue?vue&type=script&lang=ts&"
export * from "./LoadingSpinner.vue?vue&type=script&lang=ts&"
import style0 from "./LoadingSpinner.vue?vue&type=style&index=0&id=3423d853&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3423d853",
  null
  
)

export default component.exports