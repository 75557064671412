<template>
  <svg
    width="108"
    height="29"
    viewBox="0 0 108 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.0185 17.1687C41.2474 15.8771 41.2686 13.9264 40.0736 12.6033C36.7049 8.87339 29.2889 1.93183 20.8028 2.00051C12.3306 2.06907 5.13541 9.10594 1.87245 12.894C0.707723 14.2461 0.778818 16.1975 2.04001 17.4601C5.4598 20.8839 12.8053 27.0673 21.1972 26.9996C29.5763 26.9317 36.7063 20.6502 40.0185 17.1687ZM21.3725 24.0348C22.4568 24.0298 23.5134 23.8791 24.5093 23.5983L24.5003 10.2903C24.4999 9.76191 24.8917 9.32986 25.3708 9.33014L26.7649 9.33069C27.244 9.33097 27.6364 9.76357 27.6367 10.2922L27.6448 22.1454C29.295 21.0143 32.2356 18.5 32.5951 16.5099C33.5 11.5 28.5251 7.90837 23.4308 6.80909C18.1149 5.66197 13 8.5 10.7095 12.474C10.0024 14.9024 10.5331 17.3867 11.9603 19.4286L11.9582 16.4141C11.9578 15.8857 12.3497 15.4534 12.8288 15.4537L14.2228 15.4545C14.702 15.4548 15.0944 15.8874 15.0947 16.4158L15.0988 22.3287C16.0361 22.8946 17.0893 23.3427 18.2357 23.64L18.2288 13.3531C18.2284 12.8244 18.6201 12.3923 19.0993 12.3926L20.4933 12.3932C20.9725 12.3934 21.3648 12.826 21.3652 13.3547L21.3725 24.0348Z"
      fill="url(#paint0_linear_138_3179)"
    />
    <path
      d="M56.216 18.768L59.984 9H62.24L57.44 21H54.992L50.192 9H52.448L56.216 18.768ZM67.4187 18.408L70.8747 9H73.1067L68.2107 21.744C67.6827 23.104 66.9547 24.136 66.0267 24.84C65.1147 25.56 64.0667 25.88 62.8827 25.8V23.856C64.3547 23.968 65.4347 23.152 66.1227 21.408L66.3387 20.952L61.1067 9H63.3387L67.4187 18.408ZM83.0023 19.488C81.7863 20.704 80.2903 21.312 78.5143 21.312C76.7383 21.312 75.2423 20.704 74.0263 19.488C72.8103 18.272 72.2023 16.776 72.2023 15C72.2023 13.224 72.8103 11.728 74.0263 10.512C75.2423 9.296 76.7383 8.688 78.5143 8.688C80.2903 8.688 81.7863 9.296 83.0023 10.512C84.2343 11.744 84.8503 13.24 84.8503 15C84.8503 16.76 84.2343 18.256 83.0023 19.488ZM78.5143 19.272C79.7143 19.272 80.7223 18.864 81.5383 18.048C82.3543 17.232 82.7623 16.216 82.7623 15C82.7623 13.784 82.3543 12.768 81.5383 11.952C80.7223 11.136 79.7143 10.728 78.5143 10.728C77.3303 10.728 76.3303 11.136 75.5143 11.952C74.6983 12.768 74.2903 13.784 74.2903 15C74.2903 16.216 74.6983 17.232 75.5143 18.048C76.3303 18.864 77.3303 19.272 78.5143 19.272ZM94.0846 15.36V9H96.1726V21H94.0846V19.272C93.2526 20.632 91.9566 21.312 90.1966 21.312C88.7886 21.312 87.6606 20.872 86.8126 19.992C85.9646 19.096 85.5406 17.888 85.5406 16.368V9H87.6286V16.248C87.6286 17.208 87.8926 17.96 88.4206 18.504C88.9486 19.048 89.6766 19.32 90.6046 19.32C91.6766 19.32 92.5246 18.992 93.1486 18.336C93.7726 17.664 94.0846 16.672 94.0846 15.36ZM106.412 10.392L100.268 19.008H106.652V21H97.4123V19.608L103.532 10.992H97.6523V9H106.412V10.392Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_138_3179"
        x1="21"
        y1="2"
        x2="21"
        y2="27.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#50A6FB" />
        <stop offset="1" stop-color="#2D55FB" />
      </linearGradient>
    </defs>
  </svg>
</template>


<script>
export default {
    name:"LogoVue"
}
</script>