export const CAMPAIGNPAYLOAD = {
  title: "",
  description: "",
  media: "",
  budget:"",
  startDate: "",
  endDate: "",
  objective: "",
  audience: {}
};
