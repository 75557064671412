export const CAMPAIGNPAYLOAD = {
    country: "",
    state: "",
    city: "",
    phoneNumber:"",
    platform: "",
    socialMedia: "",
  };

export const AUDIENCEPAYLOAD = {
  niche:"",
  targetDemographic:"",
  ageGroup:""
}
  