<template>
  <!-- campaign icon -->
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-if="name === 'campaign'"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5414 1.95677C15.2934 2.36823 15.8333 3.18433 15.8333 4.16695V12.6668C15.8333 14.656 13.6304 15.8347 12.0107 14.6783C11.9924 14.6653 11.9747 14.6515 11.9575 14.637L11.0687 13.887C11.0559 13.8763 11.0435 13.8652 11.0315 13.8537C10.3482 13.2032 9.49637 12.7979 8.56059 12.6486V15.9167C8.56059 17.2389 7.59386 18.3333 6.27271 18.3333C4.92434 18.3333 3.98483 17.1259 3.98483 15.9167V12.4898C2.18788 12.0801 0.833313 10.3786 0.833313 8.41686C0.833313 7.31218 1.22321 6.24809 2.05713 5.48393C2.73107 4.79645 3.65512 4.25026 4.81816 4.25026H7.70271C8.98125 4.17261 10.1671 3.73502 11.0622 3.03457L11.925 2.22569C11.9417 2.21001 11.9591 2.19503 11.977 2.18078C12.7862 1.5389 13.7774 1.53872 14.5414 1.95677ZM13.0356 3.46908L12.176 4.27491C12.1593 4.2906 12.1419 4.30558 12.1239 4.31983C10.9263 5.26981 9.38422 5.82337 7.77492 5.91557C7.75904 5.91648 7.74315 5.91693 7.72725 5.91693H4.81816C4.21441 5.91693 3.69037 6.19369 3.23459 6.6637C3.22169 6.677 3.20836 6.68986 3.19461 6.70227C2.75002 7.10344 2.49998 7.69746 2.49998 8.41686C2.49998 9.82818 3.61878 10.9168 4.81816 10.9168C5.2784 10.9168 5.65149 11.2899 5.65149 11.7501V15.9167C5.65149 16.3741 6.00491 16.6667 6.27271 16.6667C6.56771 16.6667 6.89392 16.4278 6.89392 15.9167V11.7501C6.89392 11.2899 7.26702 10.9168 7.72725 10.9168C9.36703 10.9168 10.9448 11.4798 12.1618 12.6287L13.0019 13.3375C13.4824 13.6553 14.1666 13.3335 14.1666 12.6668V4.16695C14.1666 3.81626 13.9793 3.54906 13.7414 3.41886C13.5226 3.29913 13.2705 3.29521 13.0356 3.46908Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3333 5.83333C18.7936 5.83333 19.1667 6.20643 19.1667 6.66667V10C19.1667 10.4602 18.7936 10.8333 18.3333 10.8333C17.8731 10.8333 17.5 10.4602 17.5 10V6.66667C17.5 6.20643 17.8731 5.83333 18.3333 5.83333Z"
      fill="white"
    />
  </svg>

  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="name === 'filter'"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.8318 4.32578C0.75698 3.24914 1.52868 1.33325 3.08417 1.33325H12.9159C14.4714 1.33325 15.2431 3.24912 14.1683 4.32577C14.1677 4.32641 14.167 4.32706 14.1664 4.3277L10.2854 8.26082V11.6994C10.2854 12.2518 10.0171 12.8377 9.50185 13.1572L7.59807 14.4643L7.59018 14.4697C7.16617 14.752 6.67811 14.7034 6.32734 14.4964C5.98377 14.2936 5.71474 13.9083 5.71474 13.4358V8.26082L1.8318 4.32578ZM2.70384 2.93509C2.63289 3.10718 2.66531 3.27381 2.77593 3.3843L2.77937 3.38773L6.85595 7.51902C6.97905 7.64378 7.04808 7.81201 7.04808 7.98728V13.2246L8.76174 12.048C8.77307 12.0402 8.78464 12.0328 8.79643 12.0257C8.86759 11.983 8.95203 11.8584 8.95203 11.6994V7.98728C8.95203 7.81201 9.02106 7.64378 9.14417 7.51902L13.2242 3.38428C13.3348 3.2738 13.3672 3.10718 13.2963 2.93509C13.2268 2.76648 13.088 2.66659 12.9159 2.66659H3.08417C2.91209 2.66659 2.77336 2.76648 2.70384 2.93509Z"
      fill="#526DA4"
    />
  </svg>
  <!-- filter icon -->

  <!-- Dashboard Icon -->
  <svg
    v-else-if="name === 'dashboard'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6191 3.52934C10.2278 3.26778 9.7724 3.26778 9.38104 3.52934L9.36684 3.53862L4.21759 6.83039C3.6486 7.19338 3.33341 7.81978 3.33341 8.44747V15.4799C3.33341 16.1438 3.8578 16.6665 4.51501 16.6665H15.4852C16.1424 16.6665 16.6667 16.1438 16.6667 15.4799V8.44747C16.6667 7.79832 16.3234 7.21636 15.7664 6.82003L10.6333 3.53862L10.6191 3.52934ZM11.5376 2.13856C10.5895 1.50915 9.41066 1.50915 8.46258 2.13857L3.32055 5.42572C3.32043 5.42579 3.32066 5.42565 3.32055 5.42572C2.24796 6.11015 1.66675 7.27977 1.66675 8.44747V15.4799C1.66675 17.0604 2.93341 18.3332 4.51501 18.3332H15.4852C17.0668 18.3332 18.3334 17.0604 18.3334 15.4799V8.44747C18.3334 7.15676 17.6376 6.09702 16.7101 5.44613C16.7003 5.43926 16.6904 5.43259 16.6803 5.42614L11.5376 2.13856Z"
      fill="white"
    />
  </svg>
  <!-- Dashboard Icon -->

  <!-- Send Icon -->

  <svg
    v-else-if="name === 'send'"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.43337 4.21344L11.0934 2.32677C13.6334 1.4801 15.0134 2.86677 14.1734 5.40677L12.2867 11.0668C11.02 14.8734 8.94004 14.8734 7.67337 11.0668L7.11337 9.38677L5.43337 8.82677C1.6267 7.5601 1.6267 5.48677 5.43337 4.21344Z"
      stroke="#010D41"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.23999 9.09988L9.62666 6.70654"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <!-- lightning -->
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="name === 'lightning'"
  >
    <path
      d="M4.05999 8.8531H6.11999V13.6531C6.11999 14.7731 6.72665 14.9998 7.46665 14.1598L12.5133 8.42644C13.1333 7.72644 12.8733 7.14644 11.9333 7.14644H9.87332V2.34644C9.87332 1.22644 9.26665 0.999769 8.52665 1.83977L3.47999 7.5731C2.86665 8.27977 3.12665 8.8531 4.05999 8.8531Z"
      stroke="#010D41"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- lightning  -->

  <!-- views -->
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="name === 'views'"
  >
    <path
      d="M16.5 21.7733C13.3133 21.7733 10.7267 19.1867 10.7267 16C10.7267 12.8133 13.3133 10.2267 16.5 10.2267C19.6867 10.2267 22.2733 12.8133 22.2733 16C22.2733 19.1867 19.6867 21.7733 16.5 21.7733ZM16.5 12.2267C14.42 12.2267 12.7267 13.92 12.7267 16C12.7267 18.08 14.42 19.7733 16.5 19.7733C18.58 19.7733 20.2733 18.08 20.2733 16C20.2733 13.92 18.58 12.2267 16.5 12.2267Z"
      fill="#35CCFF"
    />
    <path
      d="M16.5 28.0267C11.4867 28.0267 6.75334 25.0933 3.5 20C2.08667 17.8 2.08667 14.2133 3.5 12C6.76667 6.90666 11.5 3.97333 16.5 3.97333C21.5 3.97333 26.2333 6.90666 29.4867 12C30.9 14.2 30.9 17.7867 29.4867 20C26.2333 25.0933 21.5 28.0267 16.5 28.0267ZM16.5 5.97333C12.1933 5.97333 8.07334 8.55999 5.19334 13.08C4.19334 14.64 4.19334 17.36 5.19334 18.92C8.07334 23.44 12.1933 26.0267 16.5 26.0267C20.8067 26.0267 24.9267 23.44 27.8067 18.92C28.8067 17.36 28.8067 14.64 27.8067 13.08C24.9267 8.55999 20.8067 5.97333 16.5 5.97333Z"
      fill="#0326B6"
    />
  </svg>
  <!-- views -->

  <!-- clicks -->
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="name === 'clicks'"
  >
    <path
      d="M20.75 30.3333H12.75C5.51 30.3333 2.41667 27.24 2.41667 20V12C2.41667 4.76001 5.51 1.66667 12.75 1.66667H20.75C27.99 1.66667 31.0833 4.76001 31.0833 12V20C31.0833 27.24 27.99 30.3333 20.75 30.3333ZM12.75 3.66667C6.60333 3.66667 4.41667 5.85334 4.41667 12V20C4.41667 26.1467 6.60333 28.3333 12.75 28.3333H20.75C26.8967 28.3333 29.0833 26.1467 29.0833 20V12C29.0833 5.85334 26.8967 3.66667 20.75 3.66667H12.75Z"
      fill="#0326B6"
    />
    <path
      d="M21.4567 18.9467C21.2033 18.9467 20.95 18.8533 20.75 18.6533L16.75 14.6533L12.75 18.6533C12.3633 19.04 11.7233 19.04 11.3367 18.6533C10.95 18.2667 10.95 17.6267 11.3367 17.24L16.0433 12.5333C16.43 12.1467 17.07 12.1467 17.4567 12.5333L22.1633 17.24C22.55 17.6267 22.55 18.2667 22.1633 18.6533C21.9633 18.8533 21.71 18.9467 21.4567 18.9467Z"
      fill="#35CCFF"
    />
  </svg>
  <!-- clicks -->

  <svg
    v-else-if="name === 'wallet'"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5834 16V22.6667C29.5834 26.6667 26.9167 29.3333 22.9167 29.3333H9.58335C5.58335 29.3333 2.91669 26.6667 2.91669 22.6667V16C2.91669 12.3733 5.10335 9.83999 8.50335 9.41332C8.85002 9.35999 9.21002 9.33333 9.58335 9.33333H22.9167C23.2634 9.33333 23.5967 9.34665 23.9167 9.39998C27.3567 9.79998 29.5834 12.3467 29.5834 16Z"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.9185 9.4C23.5985 9.34667 23.2652 9.33335 22.9185 9.33335H9.58519C9.21185 9.33335 8.85185 9.36002 8.50519 9.41335C8.69185 9.04002 8.95852 8.69335 9.27852 8.37335L13.6119 4.02667C15.4385 2.21334 18.3985 2.21334 20.2252 4.02667L22.5585 6.3867C23.4119 7.2267 23.8652 8.29334 23.9185 9.4Z"
      fill="#35CCFF"
      stroke="#35CCFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.5834 16.6667H25.5834C24.1167 16.6667 22.9167 17.8667 22.9167 19.3333C22.9167 20.8 24.1167 22 25.5834 22H29.5834"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'ad-spend'"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6667 27.3333H9.33332C5.33332 27.3333 2.66666 25.3333 2.66666 20.6667V11.3333C2.66666 6.66667 5.33332 4.66667 9.33332 4.66667H22.6667C26.6667 4.66667 29.3333 6.66667 29.3333 11.3333V20.6667C29.3333 25.3333 26.6667 27.3333 22.6667 27.3333Z"
      stroke="#35CCFF"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.3333 9.33333H21.3333"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6667 22.6667H6.66666"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <!-- campaign details icon -->
  <svg
    v-else-if="name === 'campaign-details'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M12.4466 1.33325H11.18C9.72663 1.33325 8.95996 2.09992 8.95996 3.55325V4.81992C8.95996 6.27325 9.72663 7.03992 11.18 7.03992H12.4466C13.9 7.03992 14.6666 6.27325 14.6666 4.81992V3.55325C14.6666 2.09992 13.9 1.33325 12.4466 1.33325Z"
      fill="#2D55FB"
    />
    <path
      opacity="0.4"
      d="M4.82671 8.95337H3.56004C2.10004 8.95337 1.33337 9.72004 1.33337 11.1734V12.44C1.33337 13.9 2.10004 14.6667 3.55337 14.6667H4.82004C6.27337 14.6667 7.04004 13.9 7.04004 12.4467V11.18C7.04671 9.72004 6.28004 8.95337 4.82671 8.95337Z"
      fill="#2D55FB"
    />
    <path
      d="M4.19337 7.05325C5.77291 7.05325 7.05337 5.77279 7.05337 4.19325C7.05337 2.61372 5.77291 1.33325 4.19337 1.33325C2.61384 1.33325 1.33337 2.61372 1.33337 4.19325C1.33337 5.77279 2.61384 7.05325 4.19337 7.05325Z"
      fill="#2D55FB"
    />
    <path
      d="M11.8067 14.6668C13.3862 14.6668 14.6667 13.3863 14.6667 11.8068C14.6667 10.2272 13.3862 8.94678 11.8067 8.94678C10.2271 8.94678 8.94666 10.2272 8.94666 11.8068C8.94666 13.3863 10.2271 14.6668 11.8067 14.6668Z"
      fill="#2D55FB"
    />
  </svg>
  <!-- campaign details icon -->

  <!-- accordion-icon -->
  <svg
    v-else-if="name === 'accordion-icon'"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="4" fill="#F3F5F9" />
    <path
      d="M20.4533 17.32L18.3133 15.18L17.0067 13.8667C16.4533 13.3133 15.5533 13.3133 15 13.8667L11.5467 17.32C11.0933 17.7733 11.42 18.5467 12.0533 18.5467H15.7933H19.9467C20.5867 18.5467 20.9067 17.7733 20.4533 17.32Z"
      fill="#2D55FB"
    />
  </svg>

  <svg
    v-else-if="name === 'build-campaign'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6933 12.6932C13.5933 12.7932 13.4667 12.8399 13.34 12.8399C13.2133 12.8399 13.0867 12.7932 12.9867 12.6932L9.68666 9.39319L10.04 9.03986L10.3933 8.68652L13.6933 11.9865C13.8867 12.1799 13.8867 12.4999 13.6933 12.6932Z"
      fill="#2D55FB"
    />
    <path
      opacity="0.4"
      d="M4.31334 6.18656L8.18001 10.0532C8.44001 10.3132 8.44001 10.7332 8.18001 10.9932L7.58 11.5999C7.04 12.1332 6.18667 12.1332 5.65334 11.5999L2.76001 8.70656C2.23334 8.17989 2.23334 7.31989 2.76001 6.78656L3.36667 6.1799C3.62667 5.92657 4.05334 5.92656 4.31334 6.18656Z"
      fill="#2D55FB"
    />
    <path
      d="M12.3933 6.7933L9.85333 9.32663C9.58666 9.5933 9.15999 9.5933 8.89332 9.32663L5.04666 5.47996C4.78 5.21329 4.78 4.78663 5.04666 4.51996L7.58666 1.97996C8.11333 1.45329 8.97332 1.45329 9.50666 1.97996L12.4 4.87329C12.92 5.39996 12.92 6.2533 12.3933 6.7933Z"
      fill="#2D55FB"
    />
    <path
      d="M5.33333 14.5H1.33333C1.05999 14.5 0.833328 14.2733 0.833328 14C0.833328 13.7267 1.05999 13.5 1.33333 13.5H5.33333C5.60666 13.5 5.83333 13.7267 5.83333 14C5.83333 14.2733 5.60666 14.5 5.33333 14.5Z"
      fill="#2D55FB"
    />
  </svg>

  <svg
    v-else-if="name === 'location-tick'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M13.7467 5.79992C13.0533 2.71325 10.36 1.33325 8 1.33325C8 1.33325 8 1.33325 7.99333 1.33325C5.64 1.33325 2.95333 2.71325 2.25333 5.79325C1.46667 9.23325 3.57333 12.1466 5.48 13.9866C6.18667 14.6666 7.09333 15.0066 8 15.0066C8.90667 15.0066 9.81333 14.6666 10.5133 13.9866C12.42 12.1466 14.5267 9.23992 13.7467 5.79992Z"
      fill="#2D55FB"
    />
    <path
      d="M7.16667 9.16671C7.04 9.16671 6.91334 9.12004 6.81334 9.02004L5.81333 8.02004C5.62 7.82671 5.62 7.50671 5.81333 7.31338C6.00667 7.12004 6.32667 7.12004 6.52 7.31338L7.16667 7.96004L9.48 5.64671C9.67334 5.45338 9.99334 5.45338 10.1867 5.64671C10.38 5.84004 10.38 6.16004 10.1867 6.35338L7.52 9.02004C7.42 9.12004 7.29333 9.16671 7.16667 9.16671Z"
      fill="#2D55FB"
    />
  </svg>

  <svg
    v-else-if="name === 'drive-traffic'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 14H13C14.1 14 15 13.1 15 12V2H6C4.5 2 3.19001 2.82999 2.51001 4.04999"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 17C2 18.66 3.34 20 5 20H6C6 18.9 6.9 18 8 18C9.1 18 10 18.9 10 20H14C14 18.9 14.9 18 16 18C17.1 18 18 18.9 18 20H19C20.66 20 22 18.66 22 17V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L18.58 6.01001C18.22 5.39001 17.56 5 16.84 5H15V12C15 13.1 14.1 14 13 14H12"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 22C9.10457 22 10 21.1046 10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 21.1046 6.89543 22 8 22Z"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 12V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L22 12Z"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 8H8"
      stroke="#35CCFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 11H6"
      stroke="#35CCFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 14H4"
      stroke="#35CCFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'increase-awareness'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 18C21.26 16.33 22 14.25 22 12C22 9.75 21.26 7.67 20 6"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 6C2.74 7.67 2 9.75 2 12C2 14.25 2.74 16.33 4 18"
      stroke="#0326B6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.8 15.5999C17.55 14.5999 18 13.3499 18 11.9999C18 10.6499 17.55 9.3999 16.8 8.3999"
      stroke="#35CCFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.20001 8.3999C6.45001 9.3999 6 10.6499 6 11.9999C6 13.3499 6.45001 14.5999 7.20001 15.5999"
      stroke="#35CCFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'campaign-creative'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M14.68 11.2135L12.5933 6.33351C12.2133 5.44018 11.6466 4.93351 11 4.90018C10.36 4.86684 9.73997 5.31351 9.26663 6.16684L7.99997 8.44018C7.7333 8.92018 7.3533 9.20684 6.93997 9.24018C6.51997 9.28018 6.09997 9.06018 5.75997 8.62684L5.6133 8.44018C5.13997 7.84684 4.5533 7.56018 3.9533 7.62018C3.3533 7.68018 2.83997 8.09351 2.49997 8.76684L1.34664 11.0668C0.933303 11.9002 0.973303 12.8668 1.45997 13.6535C1.94664 14.4402 2.7933 14.9135 3.71997 14.9135H12.2266C13.12 14.9135 13.9533 14.4668 14.4466 13.7202C14.9533 12.9735 15.0333 12.0335 14.68 11.2135Z"
      fill="#2D55FB"
    />
    <path
      d="M4.64664 5.58675C5.89113 5.58675 6.89998 4.57789 6.89998 3.33341C6.89998 2.08893 5.89113 1.08008 4.64664 1.08008C3.40216 1.08008 2.39331 2.08893 2.39331 3.33341C2.39331 4.57789 3.40216 5.58675 4.64664 5.58675Z"
      fill="#2D55FB"
    />
  </svg>

  <svg
    v-else-if="name === 'audience'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M8 8.00016C9.84095 8.00016 11.3333 6.50778 11.3333 4.66683C11.3333 2.82588 9.84095 1.3335 8 1.3335C6.15906 1.3335 4.66667 2.82588 4.66667 4.66683C4.66667 6.50778 6.15906 8.00016 8 8.00016Z"
      fill="#2D55FB"
    />
    <path
      d="M8 9.6665C4.66 9.6665 1.94 11.9065 1.94 14.6665C1.94 14.8532 2.08667 14.9998 2.27334 14.9998H13.7267C13.9133 14.9998 14.06 14.8532 14.06 14.6665C14.06 11.9065 11.34 9.6665 8 9.6665Z"
      fill="#2D55FB"
    />
  </svg>

  <svg
    v-else-if="name === 'settings'"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.34465 0.930554C8.34633 0.408565 9.56903 0.408565 10.5707 0.930554C10.5782 0.93447 10.5857 0.938495 10.5931 0.942627L15.7495 3.82904C16.767 4.36456 17.3327 5.33214 17.3327 6.38202V11.6974C17.3327 12.7125 16.7953 13.707 15.819 14.1799L10.6764 17.0586C10.669 17.0627 10.6616 17.0667 10.6541 17.0706C9.65237 17.5926 8.42967 17.5926 7.42798 17.0706C7.42046 17.0667 7.41302 17.0627 7.40564 17.0586L2.24921 14.1721C1.23171 13.6366 0.666016 12.6691 0.666016 11.6192V6.38202C0.666016 5.3331 1.23068 4.36634 2.24643 3.83052L7.31717 0.945522C7.32623 0.940369 7.33539 0.935379 7.34465 0.930554ZM8.16655 2.29071L3.09819 5.17435C3.08914 5.1795 3.07998 5.18449 3.07072 5.18932C2.59583 5.43679 2.33268 5.87271 2.33268 6.38202V11.6192C2.33268 12.1285 2.59583 12.5644 3.07072 12.8119C3.07823 12.8158 3.08568 12.8198 3.09306 12.824L8.24754 15.7093C8.74059 15.962 9.34145 15.962 9.8345 15.7093L14.989 12.824C15.0067 12.814 15.0249 12.8047 15.0433 12.796C15.3812 12.6376 15.666 12.2351 15.666 11.6974V6.38202C15.666 5.87271 15.4029 5.43679 14.928 5.18932C14.9205 5.1854 14.913 5.18138 14.9056 5.17725L9.75117 2.29191C9.2589 2.03963 8.65916 2.03922 8.16655 2.29071ZM8.99934 7.39819C8.03285 7.39819 7.24934 8.13311 7.24934 9.03968C7.24934 9.94626 8.03285 10.6812 8.99934 10.6812C9.96584 10.6812 10.7493 9.94626 10.7493 9.03968C10.7493 8.13311 9.96584 7.39819 8.99934 7.39819ZM5.58268 9.03968C5.58268 7.2697 7.11237 5.83485 8.99934 5.83485C10.8863 5.83485 12.416 7.2697 12.416 9.03968C12.416 10.8097 10.8863 12.2445 8.99934 12.2445C7.11237 12.2445 5.58268 10.8097 5.58268 9.03968Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'sites'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 18.9577C5.05841 18.9577 1.04175 14.941 1.04175 9.99935C1.04175 5.05768 5.05841 1.04102 10.0001 1.04102C14.9417 1.04102 18.9584 5.05768 18.9584 9.99935C18.9584 14.941 14.9417 18.9577 10.0001 18.9577ZM10.0001 2.29102C5.75008 2.29102 2.29175 5.74935 2.29175 9.99935C2.29175 14.2493 5.75008 17.7077 10.0001 17.7077C14.2501 17.7077 17.7084 14.2493 17.7084 9.99935C17.7084 5.74935 14.2501 2.29102 10.0001 2.29102Z"
      fill="white"
    />
    <path
      d="M7.49995 18.125H6.66662C6.32495 18.125 6.04162 17.8417 6.04162 17.5C6.04162 17.1583 6.30828 16.8833 6.64995 16.875C5.34162 12.4083 5.34162 7.59167 6.64995 3.125C6.30828 3.11667 6.04162 2.84167 6.04162 2.5C6.04162 2.15833 6.32495 1.875 6.66662 1.875H7.49995C7.69995 1.875 7.89162 1.975 8.00828 2.13333C8.12495 2.3 8.15828 2.50833 8.09162 2.7C6.52495 7.40833 6.52495 12.5917 8.09162 17.3083C8.15828 17.5 8.12495 17.7083 8.00828 17.875C7.89162 18.025 7.69995 18.125 7.49995 18.125Z"
      fill="white"
    />
    <path
      d="M12.5 18.1243C12.4333 18.1243 12.3667 18.1159 12.3 18.0909C11.975 17.9826 11.7917 17.6243 11.9083 17.2993C13.475 12.5909 13.475 7.40759 11.9083 2.69092C11.8 2.36592 11.975 2.00759 12.3 1.89925C12.6333 1.79092 12.9833 1.96592 13.0917 2.29092C14.75 7.25759 14.75 12.7243 13.0917 17.6826C13.0083 17.9576 12.7583 18.1243 12.5 18.1243Z"
      fill="white"
    />
    <path
      d="M10 14.334C7.675 14.334 5.35833 14.009 3.125 13.3506C3.11667 13.684 2.84167 13.959 2.5 13.959C2.15833 13.959 1.875 13.6756 1.875 13.334V12.5006C1.875 12.3006 1.975 12.109 2.13333 11.9923C2.3 11.8756 2.50833 11.8423 2.7 11.909C7.40833 13.4756 12.6 13.4756 17.3083 11.909C17.5 11.8423 17.7083 11.8756 17.875 11.9923C18.0417 12.109 18.1333 12.3006 18.1333 12.5006V13.334C18.1333 13.6756 17.85 13.959 17.5083 13.959C17.1667 13.959 16.8917 13.6923 16.8833 13.3506C14.6417 14.009 12.325 14.334 10 14.334Z"
      fill="white"
    />
    <path
      d="M17.4999 8.12448C17.4332 8.12448 17.3666 8.11615 17.2999 8.09115C12.5916 6.52448 7.3999 6.52448 2.69157 8.09115C2.35823 8.19948 2.00823 8.02448 1.8999 7.69948C1.7999 7.36615 1.9749 7.01615 2.2999 6.90781C7.26657 5.24948 12.7332 5.24948 17.6916 6.90781C18.0166 7.01615 18.1999 7.37448 18.0832 7.69948C18.0082 7.95781 17.7582 8.12448 17.4999 8.12448Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'website'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/bold/global">
      <path
        id="Vector"
        d="M6.375 17.4255C6.35 17.4255 6.31667 17.4422 6.29167 17.4422C4.675 16.6422 3.35834 15.3172 2.55 13.7005C2.55 13.6755 2.56667 13.6422 2.56667 13.6172C3.58334 13.9172 4.63334 14.1422 5.675 14.3172C5.85834 15.3672 6.075 16.4089 6.375 17.4255Z"
        :fill="color"
      />
      <path
        id="Vector_2"
        d="M17.45 13.7089C16.625 15.3672 15.25 16.7089 13.575 17.5172C13.8917 16.4589 14.1583 15.3922 14.3333 14.3172C15.3833 14.1422 16.4167 13.9172 17.4333 13.6172C17.425 13.6505 17.45 13.6839 17.45 13.7089Z"
        :fill="color"
      />
      <path
        id="Vector_3"
        d="M17.5167 6.42409C16.4667 6.10742 15.4083 5.84909 14.3333 5.66576C14.1583 4.59076 13.9 3.52409 13.575 2.48242C15.3 3.30742 16.6917 4.69909 17.5167 6.42409Z"
        :fill="color"
      />
      <path
        id="Vector_4"
        d="M6.375 2.57578C6.075 3.59245 5.85834 4.62578 5.68334 5.67578C4.60834 5.84245 3.54167 6.10911 2.48334 6.42578C3.29167 4.75078 4.63334 3.37578 6.29167 2.55078C6.31667 2.55078 6.35 2.57578 6.375 2.57578Z"
        :fill="color"
      />
      <path
        id="Vector_5"
        d="M12.9083 5.49102C10.975 5.27435 9.02499 5.27435 7.09166 5.49102C7.29999 4.34935 7.56666 3.20768 7.94166 2.10768C7.95833 2.04102 7.94999 1.99102 7.95833 1.92435C8.61666 1.76602 9.29166 1.66602 9.99999 1.66602C10.7 1.66602 11.3833 1.76602 12.0333 1.92435C12.0417 1.99102 12.0417 2.04102 12.0583 2.10768C12.4333 3.21602 12.7 4.34935 12.9083 5.49102Z"
        :fill="color"
      />
      <path
        id="Vector_6"
        d="M5.49167 12.9085C4.34167 12.7001 3.20834 12.4335 2.10834 12.0585C2.04167 12.0418 1.99167 12.0501 1.92501 12.0418C1.76667 11.3835 1.66667 10.7085 1.66667 10.0001C1.66667 9.30013 1.76667 8.6168 1.92501 7.9668C1.99167 7.95846 2.04167 7.95846 2.10834 7.9418C3.21667 7.57513 4.34167 7.30013 5.49167 7.0918C5.28334 9.02513 5.28334 10.9751 5.49167 12.9085Z"
        :fill="color"
      />
      <path
        id="Vector_7"
        d="M18.3333 10.0001C18.3333 10.7085 18.2333 11.3835 18.075 12.0418C18.0083 12.0501 17.9583 12.0418 17.8917 12.0585C16.7833 12.4251 15.65 12.7001 14.5083 12.9085C14.725 10.9751 14.725 9.02513 14.5083 7.0918C15.65 7.30013 16.7917 7.5668 17.8917 7.9418C17.9583 7.95846 18.0083 7.9668 18.075 7.9668C18.2333 8.62513 18.3333 9.30013 18.3333 10.0001Z"
        :fill="color"
      />
      <path
        id="Vector_8"
        d="M12.9083 14.5078C12.7 15.6578 12.4333 16.7911 12.0583 17.8911C12.0417 17.9578 12.0417 18.0078 12.0333 18.0745C11.3833 18.2328 10.7 18.3328 9.99999 18.3328C9.29166 18.3328 8.61666 18.2328 7.95833 18.0745C7.94999 18.0078 7.95833 17.9578 7.94166 17.8911C7.57499 16.7828 7.29999 15.6578 7.09166 14.5078C8.05833 14.6161 9.02499 14.6911 9.99999 14.6911C10.975 14.6911 11.95 14.6161 12.9083 14.5078Z"
        :fill="color"
      />
      <path
        id="Vector_9"
        d="M13.1361 13.1355C11.0519 13.3984 8.94814 13.3984 6.8639 13.1355C6.60093 11.0512 6.60093 8.94749 6.8639 6.86324C8.94814 6.60027 11.0519 6.60027 13.1361 6.86324C13.3991 8.94749 13.3991 11.0512 13.1361 13.1355Z"
        :fill="color"
      />
    </g>
  </svg>

  <svg
    v-else-if="name === 'game'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/bold/game">
      <g id="game">
        <path
          id="Vector"
          d="M13.875 4.76758H6.125C3.66667 4.76758 1.66667 6.76758 1.66667 9.22591V13.8759C1.66667 16.3342 3.66667 18.3342 6.125 18.3342H13.875C16.3333 18.3342 18.3333 16.3342 18.3333 13.8759V9.22591C18.3333 6.76758 16.3333 4.76758 13.875 4.76758ZM12.0833 10.0176C12.0833 9.55925 12.4583 9.18425 12.9167 9.18425C13.375 9.18425 13.75 9.55925 13.75 10.0176C13.75 10.4759 13.375 10.8592 12.9167 10.8592C12.4583 10.8592 12.0833 10.4926 12.0833 10.0342V10.0176ZM8.44167 13.3926C8.31667 13.5176 8.15833 13.5759 8 13.5759C7.84167 13.5759 7.68333 13.5176 7.55833 13.3926L6.7 12.5342L5.875 13.3592C5.75 13.4842 5.59167 13.5426 5.43333 13.5426C5.275 13.5426 5.11667 13.4842 4.99167 13.3592C4.75 13.1176 4.75 12.7176 4.99167 12.4759L5.81667 11.6509L5.01667 10.8509C4.775 10.6092 4.775 10.2092 5.01667 9.96758C5.25833 9.72591 5.65833 9.72591 5.9 9.96758L6.7 10.7676L7.525 9.94258C7.76667 9.70091 8.16667 9.70091 8.40833 9.94258C8.65 10.1842 8.65 10.5842 8.40833 10.8259L7.58333 11.6509L8.44167 12.5092C8.68333 12.7509 8.68333 13.1509 8.44167 13.3926ZM11.2833 12.5009C10.825 12.5009 10.4417 12.1259 10.4417 11.6676C10.4417 11.2092 10.8083 10.8342 11.2667 10.8342H11.2833C11.7417 10.8342 12.1167 11.2092 12.1167 11.6676C12.1167 12.1259 11.75 12.5009 11.2833 12.5009ZM12.9167 14.1426C12.4583 14.1426 12.0833 13.7759 12.0833 13.3176V13.3009C12.0833 12.8426 12.4583 12.4676 12.9167 12.4676C13.375 12.4676 13.75 12.8426 13.75 13.3009C13.75 13.7592 13.3833 14.1426 12.9167 14.1426ZM14.5667 12.5009C14.1083 12.5009 13.725 12.1259 13.725 11.6676C13.725 11.2092 14.0917 10.8342 14.55 10.8342H14.5667C15.025 10.8342 15.4 11.2092 15.4 11.6676C15.4 12.1259 15.0333 12.5009 14.5667 12.5009Z"
          :fill="color"
        />
        <path
          id="Vector_2"
          d="M11.3667 2.25768L11.3583 3.04102C11.35 3.77435 10.7417 4.38268 10 4.38268C9.875 4.38268 9.8 4.46602 9.8 4.57435C9.8 4.68268 9.88333 4.76602 9.99167 4.76602H8.65C8.64167 4.70768 8.63333 4.64102 8.63333 4.57435C8.63333 3.82435 9.24167 3.21602 9.98333 3.21602C10.1083 3.21602 10.1917 3.13268 10.1917 3.02435L10.2 2.24102C10.2083 1.92435 10.4667 1.66602 10.7833 1.66602H10.7917C11.1167 1.66602 11.3667 1.93268 11.3667 2.25768Z"
          :fill="color"
        />
      </g>
    </g>
  </svg>

  <svg
    v-else-if="name === 'mobile'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/bold/mobile">
      <g id="mobile">
        <path
          id="Vector"
          d="M13.5333 1.66602H6.46666C4.16666 1.66602 3.33333 2.49935 3.33333 4.84102V15.1577C3.33333 17.4994 4.16666 18.3327 6.46666 18.3327H13.525C15.8333 18.3327 16.6667 17.4994 16.6667 15.1577V4.84102C16.6667 2.49935 15.8333 1.66602 13.5333 1.66602ZM9.99999 16.0827C9.19999 16.0827 8.54166 15.4243 8.54166 14.6243C8.54166 13.8243 9.19999 13.166 9.99999 13.166C10.8 13.166 11.4583 13.8243 11.4583 14.6243C11.4583 15.4243 10.8 16.0827 9.99999 16.0827ZM11.6667 5.20768H8.33333C7.99166 5.20768 7.70833 4.92435 7.70833 4.58268C7.70833 4.24102 7.99166 3.95768 8.33333 3.95768H11.6667C12.0083 3.95768 12.2917 4.24102 12.2917 4.58268C12.2917 4.92435 12.0083 5.20768 11.6667 5.20768Z"
          :fill="color"
        />
      </g>
    </g>
  </svg>

  <svg
    v-else-if="name === 'telegram'"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Telegram" clip-path="url(#clip0_642_4089)">
      <path
        id="Vector"
        d="M6 0.480469C9.04871 0.480469 11.52 2.95175 11.52 6.00047C11.52 9.04919 9.04871 11.5205 6 11.5205C2.95128 11.5205 0.479996 9.04919 0.479996 6.00047C0.479996 2.95175 2.95128 0.480469 6 0.480469ZM7.90415 8.25047C8.00567 7.93895 8.48135 4.83431 8.54016 4.22255C8.55791 4.03727 8.49936 3.91415 8.38464 3.85919C8.24592 3.79247 8.04047 3.82583 7.80216 3.91175C7.47528 4.02959 3.2964 5.80391 3.05496 5.90663C2.826 6.00383 2.60952 6.10991 2.60952 6.26351C2.60952 6.37151 2.6736 6.43223 2.85024 6.49535C3.03408 6.56087 3.49704 6.70127 3.7704 6.77663C4.03368 6.84935 4.33344 6.78623 4.50144 6.68183C4.67952 6.57119 6.73464 5.19599 6.88224 5.07551C7.0296 4.95503 7.1472 5.10935 7.02672 5.23007C6.90624 5.35055 5.49551 6.71975 5.30951 6.90935C5.08368 7.13951 5.244 7.37807 5.39544 7.47359C5.56848 7.58255 6.81287 8.41727 7.00032 8.55119C7.18776 8.68511 7.37784 8.74583 7.55184 8.74583C7.72583 8.74583 7.81751 8.51663 7.90415 8.25047Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_642_4089">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'whatsapp'"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/bold/whatsapp">
      <g id="whatsapp">
        <path
          id="Vector"
          d="M10.99 5.70522C10.82 2.80522 8.18499 0.570225 5.14999 1.07023C3.05999 1.41523 1.38499 3.11021 1.05999 5.20021C0.869994 6.41021 1.12 7.55522 1.665 8.50022L1.22 10.1552C1.12 10.5302 1.46499 10.8702 1.83499 10.7652L3.46499 10.3152C4.20499 10.7502 5.06999 11.0002 5.99499 11.0002C8.81499 11.0002 11.155 8.51522 10.99 5.70522ZM8.44 7.86022C8.395 7.95022 8.33999 8.03522 8.26999 8.11522C8.14499 8.25022 8.00998 8.35022 7.85998 8.41022C7.70998 8.47522 7.54499 8.50522 7.36999 8.50522C7.11499 8.50522 6.83999 8.44523 6.55499 8.32023C6.26499 8.19523 5.98 8.03021 5.695 7.82521C5.405 7.61521 5.135 7.38022 4.875 7.12522C4.615 6.86522 4.38498 6.59021 4.17498 6.30521C3.96998 6.02021 3.80499 5.73521 3.68499 5.45021C3.56499 5.16522 3.505 4.89022 3.505 4.63022C3.505 4.46022 3.535 4.29522 3.595 4.14522C3.655 3.99022 3.75001 3.85022 3.88501 3.72522C4.04501 3.56522 4.22 3.49022 4.40499 3.49022C4.47499 3.49022 4.54498 3.50522 4.60998 3.53522C4.67498 3.56522 4.73499 3.61022 4.77999 3.67522L5.35998 4.49521C5.40498 4.56021 5.43999 4.61522 5.45999 4.67022C5.48499 4.72522 5.49499 4.77521 5.49499 4.82521C5.49499 4.88521 5.475 4.94522 5.44 5.00522C5.405 5.06522 5.35998 5.12522 5.29998 5.18522L5.10998 5.38521C5.07998 5.41521 5.07 5.44522 5.07 5.48522C5.07 5.50522 5.07498 5.52522 5.07998 5.54522C5.08998 5.56522 5.095 5.58022 5.1 5.59522C5.145 5.68022 5.22499 5.78521 5.33499 5.91521C5.44999 6.04521 5.57001 6.18022 5.70001 6.31022C5.83501 6.44522 5.965 6.56521 6.1 6.68021C6.23 6.79021 6.33998 6.86522 6.42498 6.91022C6.43998 6.91522 6.455 6.92521 6.47 6.93021C6.49 6.94021 6.51 6.94022 6.535 6.94022C6.58 6.94022 6.61001 6.92522 6.64001 6.89522L6.82998 6.70522C6.89498 6.64022 6.95501 6.59522 7.01001 6.56522C7.07001 6.53022 7.125 6.51021 7.19 6.51021C7.24 6.51021 7.29 6.52022 7.345 6.54522C7.4 6.57022 7.45999 6.60022 7.51999 6.64522L8.35 7.23522C8.415 7.28022 8.45999 7.33522 8.48999 7.39522C8.51499 7.46022 8.52999 7.52021 8.52999 7.59021C8.49999 7.67521 8.48 7.77022 8.44 7.86022Z"
          :fill="color"
        />
      </g>
    </g>
  </svg>

  <svg
    v-else-if="name === 'instagram'"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/bold/instagram">
      <g id="instagram">
        <path
          id="Vector"
          d="M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM6 7.94C4.93 7.94 4.06 7.07 4.06 6C4.06 4.93 4.93 4.06 6 4.06C7.07 4.06 7.94 4.93 7.94 6C7.94 7.07 7.07 7.94 6 7.94ZM8.96 3.44C8.935 3.5 8.9 3.555 8.855 3.605C8.805 3.65 8.75 3.685 8.69 3.71C8.63 3.735 8.565 3.75 8.5 3.75C8.365 3.75 8.24 3.7 8.145 3.605C8.1 3.555 8.065 3.5 8.04 3.44C8.015 3.38 8 3.315 8 3.25C8 3.185 8.015 3.12 8.04 3.06C8.065 2.995 8.1 2.945 8.145 2.895C8.26 2.78 8.435 2.725 8.595 2.76C8.63 2.765 8.66 2.775 8.69 2.79C8.72 2.8 8.75 2.815 8.78 2.835C8.805 2.85 8.83 2.875 8.855 2.895C8.9 2.945 8.935 2.995 8.96 3.06C8.985 3.12 9 3.185 9 3.25C9 3.315 8.985 3.38 8.96 3.44Z"
          :fill="color"
        />
      </g>
    </g>
  </svg>

  <svg
    v-else-if="name === 'facebook'"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/bold/facebook">
      <g id="facebook">
        <path
          id="Vector"
          d="M11 8.095C11 9.915 9.915 11 8.095 11H7.5C7.225 11 7 10.775 7 10.5V7.61501C7 7.48001 7.10999 7.36501 7.245 7.36501L8.125 7.35001C8.195 7.34501 8.255 7.29501 8.27 7.22501L8.44501 6.27C8.46001 6.18 8.39 6.095 8.295 6.095L7.23 6.11C7.09 6.11 6.98001 6.00001 6.97501 5.86501L6.955 4.64C6.955 4.56 7.02 4.49001 7.105 4.49001L8.30499 4.47C8.38999 4.47 8.455 4.40501 8.455 4.32001L8.435 3.12C8.435 3.035 8.37 2.97 8.285 2.97L6.935 2.99001C6.105 3.00501 5.44501 3.685 5.46001 4.515L5.485 5.89C5.49 6.03 5.38001 6.14 5.24001 6.145L4.64 6.155C4.555 6.155 4.49001 6.21999 4.49001 6.30499L4.505 7.255C4.505 7.34 4.57 7.405 4.655 7.405L5.255 7.395C5.395 7.395 5.50499 7.505 5.50999 7.64L5.55499 10.49C5.55999 10.77 5.33499 11 5.05499 11H3.905C2.085 11 1 9.915 1 8.09V3.905C1 2.085 2.085 1 3.905 1H8.095C9.915 1 11 2.085 11 3.905V8.095Z"
          :fill="color"
        />
      </g>
    </g>
  </svg>

  <svg v-else-if="name==='global'" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 29.3327C23.8638 29.3327 29.8333 23.3631 29.8333 15.9993C29.8333 8.63555 23.8638 2.66602 16.5 2.66602C9.13616 2.66602 3.16663 8.63555 3.16663 15.9993C3.16663 23.3631 9.13616 29.3327 16.5 29.3327Z" stroke="#0326B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.1667 4H12.5C9.90005 11.7867 9.90005 20.2133 12.5 28H11.1667" fill="#35CCFF"/>
<path d="M11.1667 4H12.5C9.90005 11.7867 9.90005 20.2133 12.5 28H11.1667" stroke="#35CCFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.5 4C23.1 11.7867 23.1 20.2133 20.5 28" stroke="#0326B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.5 21.3333V20C12.2867 22.6 20.7133 22.6 28.5 20V21.3333" stroke="#0326B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.5 12.0008C12.2867 9.40078 20.7133 9.40078 28.5 12.0008" stroke="#0326B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


<svg v-else-if="name==='reach'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/outline/moneys">
<g id="moneys">
<path id="Vector" d="M19.8535 24.293H8.1335C7.44017 24.293 6.78682 24.2264 6.20015 24.093C5.89349 24.053 5.52017 23.9463 5.1335 23.7996C3.36017 23.133 1.26685 21.493 1.26685 17.413V10.5463C1.26685 6.18631 3.7735 3.67969 8.1335 3.67969H19.8535C23.5335 3.67969 25.9068 5.43965 26.5468 8.63965C26.6668 9.22632 26.7202 9.85297 26.7202 10.5463V17.413C26.7202 21.7997 24.2268 24.293 19.8535 24.293ZM8.14681 5.70638C4.86681 5.70638 3.28015 7.293 3.28015 10.573V17.4397C3.28015 19.8264 4.12017 21.293 5.84017 21.9464C6.10684 22.0397 6.36018 22.1063 6.60018 22.1463C7.10685 22.253 7.60014 22.3063 8.14681 22.3063H19.8668C23.1468 22.3063 24.7335 20.7197 24.7335 17.4397V10.573C24.7335 10.013 24.6935 9.51966 24.6002 9.06633C24.1468 6.79966 22.6002 5.70638 19.8668 5.70638H8.14681Z" fill="#35CCFF"/>
<path id="Vector_2" d="M23.8535 28.2931H12.1335C11.0002 28.2931 9.98686 28.1331 9.12019 27.7998C7.16019 27.0664 5.88018 25.5198 5.44018 23.3065C5.37351 22.9731 5.48021 22.6265 5.72021 22.3998C5.96021 22.1598 6.30688 22.0665 6.64021 22.1465C7.08021 22.2398 7.57354 22.2931 8.13354 22.2931H19.8535C23.1335 22.2931 24.7202 20.7065 24.7202 17.4265V10.5598C24.7202 9.99979 24.6802 9.50645 24.5869 9.05312C24.5202 8.71978 24.6269 8.38646 24.8535 8.14646C25.0935 7.90646 25.4269 7.79978 25.7602 7.87978C28.9602 8.53311 30.7202 10.9065 30.7202 14.5598V21.4265C30.7202 25.7998 28.2269 28.2931 23.8535 28.2931ZM7.89355 24.2931C8.32021 25.0664 8.9602 25.6132 9.8402 25.9332C10.4802 26.1732 11.2535 26.2931 12.1468 26.2931H23.8669C27.1469 26.2931 28.7335 24.7065 28.7335 21.4265V14.5598C28.7335 12.4531 28.0802 11.0531 26.7335 10.3198C26.7335 10.3998 26.7335 10.4798 26.7335 10.5598V17.4265C26.7335 21.7865 24.2269 24.2931 19.8669 24.2931H8.14684C8.05351 24.2931 7.97355 24.2931 7.89355 24.2931Z" fill="#0326B6"/>
<path id="Vector_3" d="M13.9998 18.5205C11.5064 18.5205 9.47974 16.4938 9.47974 14.0005C9.47974 11.5072 11.5064 9.48047 13.9998 9.48047C16.4931 9.48047 18.5198 11.5072 18.5198 14.0005C18.5198 16.4938 16.4931 18.5205 13.9998 18.5205ZM13.9998 11.4805C12.6131 11.4805 11.4797 12.6138 11.4797 14.0005C11.4797 15.3872 12.6131 16.5205 13.9998 16.5205C15.3864 16.5205 16.5198 15.3872 16.5198 14.0005C16.5198 12.6138 15.3864 11.4805 13.9998 11.4805Z" fill="#35CCFF"/>
<path id="Vector_4" d="M6.37378 17.9331C5.82711 17.9331 5.37378 17.4798 5.37378 16.9331V11.0664C5.37378 10.5197 5.82711 10.0664 6.37378 10.0664C6.92045 10.0664 7.37378 10.5197 7.37378 11.0664V16.9331C7.37378 17.4798 6.93378 17.9331 6.37378 17.9331Z" fill="#35CCFF"/>
<path id="Vector_5" d="M21.6133 17.9331C21.0666 17.9331 20.6133 17.4798 20.6133 16.9331V11.0664C20.6133 10.5197 21.0666 10.0664 21.6133 10.0664C22.1599 10.0664 22.6133 10.5197 22.6133 11.0664V16.9331C22.6133 17.4798 22.1733 17.9331 21.6133 17.9331Z" fill="#35CCFF"/>
</g>
</g>
</svg>

<svg v-else-if="name==='engagement'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.7066 29.4673H16.6399C15.8933 29.4673 14.2666 29.2406 13.3999 28.374L9.35994 25.254L10.5866 23.6673L14.7199 26.8673C15.0533 27.1873 15.8933 27.454 16.6399 27.454H21.7066C22.9066 27.454 24.1999 26.494 24.4666 25.414L27.6933 15.614C27.9066 15.0273 27.8666 14.494 27.5866 14.1073C27.2933 13.694 26.7599 13.454 26.1066 13.454H20.7733C20.0799 13.454 19.4399 13.1606 18.9999 12.654C18.5466 12.134 18.3466 11.4406 18.4533 10.7206L19.1199 6.44064C19.2799 5.69397 18.7733 4.85397 18.0533 4.61397C17.3999 4.37397 16.5599 4.72064 16.2666 5.1473L10.7999 13.2806L9.14661 12.174L14.6133 4.04064C15.4533 2.7873 17.2933 2.1873 18.7333 2.73397C20.3999 3.28064 21.4666 5.12064 21.0933 6.8273L20.4399 11.0273C20.4266 11.1206 20.4266 11.254 20.5199 11.3606C20.5866 11.4273 20.6799 11.4673 20.7866 11.4673H26.1199C27.4266 11.4673 28.5599 12.014 29.2266 12.9606C29.8799 13.8806 30.0133 15.094 29.5866 16.2673L26.3999 25.974C25.9066 27.9073 23.8533 29.4673 21.7066 29.4673Z" fill="#0326B6"/>
<path d="M7.17334 28.0005H5.84001C3.37334 28.0005 2.17334 26.8405 2.17334 24.4672V11.4005C2.17334 9.02719 3.37334 7.86719 5.84001 7.86719H7.17334C9.64001 7.86719 10.84 9.02719 10.84 11.4005V24.4672C10.84 26.8405 9.64001 28.0005 7.17334 28.0005ZM5.84001 9.86719C4.38667 9.86719 4.17334 10.2139 4.17334 11.4005V24.4672C4.17334 25.6539 4.38667 26.0005 5.84001 26.0005H7.17334C8.62667 26.0005 8.84001 25.6539 8.84001 24.4672V11.4005C8.84001 10.2139 8.62667 9.86719 7.17334 9.86719H5.84001Z" fill="#35CCFF"/>
</svg>

</template>
  
  
  
<script>
export default {
  name: "CustomIcon",
  props: {
    name: {
      type: String,
    },
    color: {
      type: String,
    },
    className: {
      type: String,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
  
  
<style scoped>
.pointer {
  cursor: pointer;
}
</style>