var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"flutterwave",staticClass:"d-none"},[_c('flutterwave-pay-button',{staticClass:"class-name",attrs:{"id":"flutterwave","tx_ref":_vm.generateReference(),"amount":_vm.amount,"currency":_vm.currency,"payment_options":"card,ussd,mobilemoney,transfer,banktransfer","redirect_url":"","meta":{
      counsumer_id: '7898',
      consumer_mac: 'kjs9s8ss7dd',
    },"customer":{
      name: _vm.name,
      email: _vm.email,
    },"customizations":{
      title: 'Vyouz',
      description: 'Fund wallet',
      logo: 'https://i.ibb.co/gdPtv0k/Logo-3.png',
    },"callback":_vm.makePaymentCallback,"onclose":_vm.closedPaymentModal}},[_vm._v(" Click To Pay ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }