export const SIGNUPPAYLOAD = {
    firstName: "",
    lastName: "",
    email: "",
    country: "Nigeria",
    businesstype: "vendor",
    password: "",
  };
  
  export const LOGINPAYLOAD = {
    email: "",
    password: "",
  };
  
  export const EMAILPAYLOAD = {
    email: "",
  };
  
  export const PASSWORDPAYLOAD = {
    password: "",
    confirmpassword:""
  };